$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f8f8f8;
$foreground-color: white;
$input-background: white;
$dark-btn-background: #131113;
$light-btn-background: #ececec;
$button-text-color: #fff;
// #7e3f97
$theme-color-1: #1c3e72;
$theme-color-2: #af9fb6;
$theme-color-3: #82628f;
$theme-color-4: #1c3e72;
$theme-color-5: #1c3e72;
$theme-color-6: #a197a7;
$primary-color: #1c3e72;
$secondary-color: #1c3e72;
$muted-color: #909090;
$gradient-color-1: #7e3f97;
$gradient-color-2: #896899;
$gradient-color-3: #531d6e;
$header-background:#ae62ce;
$lp-bg-color-1: #510e72;
$lp-bg-color-2: #43145a;
$lp-bg-color-3: #7811ac;
$lp-bg-color-4: #a21ce6;
$shadow-offsets-top: 1 3 10 14 19;
$shadow-blurs-top: 1.5 5 10 14 19;
$shadow-opacities-top: 0.04 0.1 0.19 0.25 0.3;
$shadow-offsets-bottom: 1 3 6 10 15;
$shadow-blurs-bottom: 3 6 6 5 6;
$shadow-opacities-bottom: 0.04 0.1 0.2 0.22 0.22;
$logo-path: "../../img/flogo.png";
$logo-path-mobile: "../../logos/logo.png";
$logo-full-path: "../../logos/logo.png";
$lp-logo-path-pinned: "../../logos/black.svg";
$lp-logo-path: "../../logos/logo.png";
@import "../_mixins.scss";
@import "../_vien.style.scss";
@import "../_toggle";